import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import * as APIS from "../../../libs/apis";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import GridTable, {
  CenterCell,
  NumberCell,
} from "components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import styled from "styled-components";
import Flex from "components/flex/Flex";
import { TiDocumentText } from "react-icons/ti";
import { formatTime } from "libs/utils";
import KendoButton from "components/button/KendoButton";
import SmallTitle from "components/title/SmallTitle";
import { Input } from "@progress/kendo-react-inputs";

const PurchaseDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const [detailData, setDetailData] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchDetail();
  }, [state.id]);

  const fetchDetail = () => {
    dispatch(loadingStart);
    APIS.getConsignmentDetail(state.id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const updatedData = processDetailData(data);
          setDetailData(updatedData);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const processDetailData = (data) => {
    const totalData = calculateTotals(data.purchaseItemResponseList);

    return {
      ...data,
      consignmentSearchRes: {
        ...data.consignmentSearchRes,
        tradeAt: formatDate(data.consignmentSearchRes.tradeAt),
        assetType: formatAssetType(data.consignmentSearchRes.assetType),
      },
      purchaseItemResponseList: [
        ...data.purchaseItemResponseList,
        createTotalRow(totalData),
      ],
      appraisalRequestStatus: data.appraisalRequestStatus.map((item) => ({
        ...item,
        updatedAt: item.updatedAt ? formatDate(item.updatedAt) : "-",
        managerName: item.managerName ? item.managerName : "-",
      })),
    };
  };

  const calculateTotals = (list) =>
    list.reduce(
      (acc, item) => {
        acc.appraisalWeightGram =
          Math.round(
            (acc.appraisalWeightGram + item.appraisalWeightGram) * 1000
          ) / 1000;
        acc.paymentKrw += item.paymentKrw;
        acc.shopFee += item.shopFee;
        return acc;
      },
      {
        appraisalWeightGram: 0,
        paymentKrw: 0,
        shopFee: 0,
      }
    );

  const createTotalRow = (totals) => ({
    purityTypeName: "합계",
    name: "",
    basicPct: "",
    productWeightGram: "",
    appraisalWeightGram: totals.appraisalWeightGram,
    etcFeeKrw: "",
    pureFee: "",
    currentConsignmentPrice: "",
    paymentKrw: totals.paymentKrw,
    shopFee: totals.shopFee,
  });

  const formatDate = (date) =>
    date ? formatTime(date, "YYYY-MM-DD HH:mm") : "-";

  const formatAssetType = (type) =>
    type.replace("GOLD", "금").replace("SILVER", "은");

  const handleClickApproval = (type) => {
    const data = {
      requestId: consignmentSearchRes.id,
      status: type,
      message: message,
    };

    dispatch(loadingStart);
    APIS.postConsignmentApproval(data)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(
            actionOpen(
              "처리되었습니다.",
              () => {
                fetchDetail();
              },
              null,
              null,
              true
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const {
    consignmentSearchRes = {},
    purchaseItemResponseList = [],
    appraisalRequestStatus = [],
  } = detailData;

  return (
    <Container>
      <DetailTable data={[consignmentSearchRes]} />
      <SectionHeader title="감정결과">
        {consignmentSearchRes.status === "승인대기" && (
          <>
            <Input
              style={{ width: 600 }}
              type="text"
              value={message}
              placeholder="승인 / 미승인 사유 입력"
              autoFocus
              onChange={(e) => setMessage(e.target.value)}
            />
            <KendoButton
              onClick={() => {
                handleClickApproval("CONFIRM_DENY");
              }}>
              감정 미승인
            </KendoButton>
            <KendoButton
              onClick={() => {
                handleClickApproval("VERIFY_COMPLETE");
              }}>
              감정 승인
            </KendoButton>
          </>
        )}
      </SectionHeader>
      <ResultTable data={purchaseItemResponseList} />
      <HistoryTable data={appraisalRequestStatus} />
    </Container>
  );
};

export default PurchaseDetail;

const DetailTable = ({ data }) => (
  <GridTable data={data}>
    <GridColumn title="접수번호" field="tradeNo" cell={CenterCell} />
    <GridColumn title="일시" field="tradeAt" cell={CenterCell} />
    <GridColumn title="이름" field="userName" cell={CenterCell} />
    <GridColumn title="고객번호" field="userId" cell={CenterCell} />
    <GridColumn title="자산" field="assetType" cell={CenterCell} />
    <GridColumn title="중량(g)" field="appraisalWeightGram" cell={NumberCell} />
    <GridColumn title="시세" field="price" cell={NumberCell} />
    <GridColumn title="지급액" field="paymentKrw" cell={NumberCell} />
    <GridColumn title="수수료" field="shopFee" cell={NumberCell} />
    <GridColumn title="대리점" field="shopName" cell={CenterCell} />
    <GridColumn title="상태" field="status" cell={CenterCell} />
    <GridColumn
      title="내역"
      cell={(props) => (
        <CenterCell {...props}>
          {props.dataItem.reportPath ? (
            <TiDocumentText
              style={{ width: 25, height: 25, cursor: "pointer" }}
              onClick={() => window.open(props.dataItem.reportPath, "_blank")}
            />
          ) : (
            <></>
          )}
        </CenterCell>
      )}
    />
  </GridTable>
);

const SectionHeader = ({ title, children }) => (
  <Header row>
    <SmallTitle>{title}</SmallTitle>
    <Flex row style={{ gap: 5 }}>
      {children}
    </Flex>
  </Header>
);

const ResultTable = ({ data }) => (
  <GridTable data={data}>
    <GridColumn title="구분" field="purityTypeName" cell={CenterCell} />
    <GridColumn title="품목" field="name" cell={CenterCell} />
    <GridColumn title="기준비율" field="basicPct" cell={NumberCell} />
    <GridColumn
      title="측정 실중량(g)"
      field="productWeightGram"
      cell={NumberCell}
    />
    <GridColumn
      title="환산중량(g)"
      field="appraisalWeightGram"
      cell={NumberCell}
    />
    <GridColumn title="추가차감(돈)" field="etcFeeKrw" cell={NumberCell} />
    <GridColumn title="정제료(돈)" field="pureFee" cell={NumberCell} />
    <GridColumn
      title="적용단가(돈)"
      field="currentConsignmentPrice"
      cell={NumberCell}
    />
    <GridColumn title="지급액" field="paymentKrw" cell={NumberCell} />
    <GridColumn title="수수료" field="shopFee" cell={NumberCell} />
  </GridTable>
);

const HistoryTable = ({ data }) => (
  <GridTable data={data} style={{ marginTop: 25 }}>
    <GridColumn title="일시" field="updatedAt" cell={CenterCell} />
    <GridColumn title="상태" field="status" cell={CenterCell} />
    <GridColumn title="관리자" field="managerName" cell={CenterCell} />
    <GridColumn title="메모(출금 계좌)" field="appraiseMemo" />
  </GridTable>
);

const Container = styled(Flex)`
  flex: 1;
  padding: 25px;
`;

const Header = styled(Flex)`
  justify-content: space-between;
  margin: 15px 0px 10px 0px;
`;

import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import cloneDeep from "lodash.clonedeep";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import Text from "components/text/Text";
import Filters from "components/filters/Filters";
import PageLabel from "components/page-label/PageLabel";
import DataTable from "components/table/DataTable";
import Flex from "components/flex/Flex";
import GridTable, { centerCell } from "components/gird-table/GridTable";

import { actionOpen, actionError } from "redux/action/ActionActions";
import { openModal } from "redux/modal/ModalReducer";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import {
  formatPhone,
  numFormat,
  getPurityLabel,
  objToQueryStr,
  base64toBlob,
} from "services/utils";
import fonts from "libs/fonts";
import routes from "libs/routes";
import * as APIS from "libs/apis";
import consts, { getDefaultPageSize } from "libs/consts";
import { formatTime, getNoProcessLabel, parseToBr } from "libs/utils";
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const gridName = "AppraisalReceiveProduct";

export default function AppraisalStatus({}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyle();
  const location = useLocation();
  const state = location.state || {};
  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const [goldWeight, setGoldWeight] = useState(0);
  const [silverWeight, setSilverWeight] = useState(0);
  const [waitingStatus, setWaitingStatus] = useState("");
  const [waitingApprovalCount, setWaitingApprovalCount] = useState(0);
  const [waitingReturnCount, setWaitingReturnCount] = useState(0);
  const authReducer = useSelector((s) => s.auth);
  const { associateShops } = useSelector((s) => s.shop);
  let { isKorda } = authReducer;

  useEffect(() => {
    if (state?.list?.length !== 0) {
      // 시세 조회
      APIS.getcurrentmarketprice("GOLD")
        .then(({ data: { data } }) => setGoldGramPrice(data))
        .catch((err) => dispatch(actionError(err)));

      APIS.getcurrentmarketprice("SILVER")
        .then(({ data: { data } }) => setSilverGramPrice(data))
        .catch((err) => dispatch(actionError(err)));

      // 감정 차감율 조회
      APIS.getAppraisalWeightRates()
        .then(({ data: { data } }) => {
          setWeightRates(data.content);
        })
        .catch((err) => dispatch(actionError(err)));
    }
  }, [state.list]);

  //중량 합계들 값 바꾸는 함수
  useEffect(() => {
    if (state.total === 0) {
      setGoldWeight(0);
      setSilverWeight(0);
      // setWaitingApprovalCount(0);
      // setWaitingReturnCount(0);
    }
  }, [state.total]);

  // 제휴사 목록 조회
  useEffect(() => {}, [associateShops]);

  useEffect(() => {
    if (state.list) {
      fetchList();
    }
  }, [state.page, state.sortType, state.size]);

  const fetchList = () => {
    const param = {
      ...state,
      startDate: state?.startDate,
      endDate: state?.endDate,
      dateType: state?.dateType,
    };
    delete param["list"];

    dispatch(loadingStart);
    APIS.getAppraisalRequests(objToQueryStr(param), isKorda)
      .then((res) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: res.data.data.totalElements,
            list: res.data.data.content,
          },
        });
        searchTotalWeight();
        searchWaitingCount();
        setWaitingStatus("");
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const onClickExcelDownload = () => {
    const param = {
      ...state,
      startDate: state?.startDate,
      endDate: state?.endDate,
      dateType: state?.dateType,
    };
    delete param["list"];
    delete param.page;
    delete param.size;

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    dispatch(loadingStart);
    APIS.getAppraisalRequestsExcel(objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `감정현황_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  const searchTotalWeight = (type, waitStatusType) => {
    const param = {
      ...state,
    };
    delete param["list"];

    dispatch(loadingStart);
    type === "waitSearch"
      ? APIS.getAppraisalTotalWeight(
          objToQueryStr({
            status: waitStatusType,
            ...getDefaultPageSize(),
            sortType: "DESC",
            selected: [],
          }),
          isKorda
        )
          .then((res) => {
            let goldWeight = 0,
              silverWeight = 0;
            res?.data?.data.forEach((item) => {
              if (item.assetType === "GOLD") {
                goldWeight = item.gram || 0;
              }
              if (item.assetType === "SILVER") {
                silverWeight = item.gram || 0;
              }
            });
            setGoldWeight(goldWeight);
            setSilverWeight(silverWeight);
          })
          .catch((err) => {
            setGoldWeight(0);
            setSilverWeight(0);
            dispatch(actionError(err));
          })
          .finally(() => dispatch(loadingEnd))
      : APIS.getAppraisalTotalWeight(objToQueryStr(param), isKorda)
          .then((res) => {
            let goldWeight = 0,
              silverWeight = 0;
            res?.data?.data.forEach((item) => {
              if (item.assetType === "GOLD") {
                goldWeight = item.gram || 0;
              }
              if (item.assetType === "SILVER") {
                silverWeight = item.gram || 0;
              }
            });
            setGoldWeight(goldWeight);
            setSilverWeight(silverWeight);
          })
          .catch((err) => {
            setGoldWeight(0);
            setSilverWeight(0);
            dispatch(actionError(err));
          })
          .finally(() => dispatch(loadingEnd));
  };

  const searchWaitingCount = () => {
    dispatch(loadingStart);
    APIS.getAppraisalWaiting()
      .then((res) => {
        let waitApproval = 0,
          waitReturn = 0;
        res?.data?.data?.forEach((item) => {
          if (item.status === "승인대기") {
            waitApproval = item.count || 0;
          }
          if (item.status === "반송대기") {
            waitReturn = item.count || 0;
          }
        });
        setWaitingApprovalCount(waitApproval);
        setWaitingReturnCount(waitReturn);
      })
      .catch((err) => {
        setWaitingApprovalCount(0);
        setWaitingReturnCount(0);
        dispatch(actionError(err));
      })
      .finally(() => dispatch(loadingEnd));
  };

  const onChange = (key, value) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        [key]: value,
        page: 0,
      },
    });
  };

  const waitSearch = (statusType) => {
    dispatch(loadingStart);
    APIS.getAppraisalRequests(
      objToQueryStr({
        status: statusType,
        ...getDefaultPageSize(),
        sortType: "DESC",
        selected: [],
      }),
      isKorda
    )
      .then((res) => {
        history.replace({
          pathname: history.location.pathname,
          state: {
            ...state,
            total: res.data.data.totalElements,
            list: res.data.data.content,
          },
        });
        searchTotalWeight("waitSearch", statusType);
        searchWaitingCount();
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const parseData = useMemo(() => {
    return state.list?.map((row) => {
      // 요청일
      let requestDate = "-";
      if (row.createdAt) {
        requestDate = formatTime(row.createdAt, "YYYY-MM-DD");
      }
      // 감정 정보
      let info = "-";
      if (row.appraisalWeightGram) {
        info = `${numFormat(row.appraisalWeightGram)}g`;
      }
      // 고객
      let customer = "-";
      if (row.user) {
        customer = `${row.user.name} ${formatPhone(row.user.phone)}`;
      }
      //고객 번호
      let userId = "-";
      if (row.user) {
        userId = row.user.id;
      }
      // 접수 방법
      let method = row.method;
      method += ` (${row.shopName})`;
      // 예약일시
      let reservationDateTime = "-";
      if (row.visitDate) {
        reservationDateTime = row.visitDate
          ? `${row.visitDate} ${row.visitTime ? row.visitTime : ""}`
          : "-";
      }
      // 교환완료일시
      let completedAt = "-";
      if (row.completedAt) {
        completedAt = moment(row.completedAt).format("YYYY.MM.DD HH:mm");
      }
      // 최종처리일시
      let lastProcessingDate = "-";
      if (row.updatedAt) {
        lastProcessingDate = moment(row.updatedAt).format("YYYY.MM.DD HH:mm");
      }
      let stockStatus = "-";
      if (row.stockStatus) {
        stockStatus = row.stockStatus
          .replace("CANCEL_WAIT", "입고취소 요청")
          .replace("WAIT", "입고대기")
          .replace("DELIVERY", "입고배송")
          .replace("TAKE", "재고수령")
          .replace("COMPLETE", "입고완료");
      }
      return {
        ...row,
        requestDate,
        info,
        customer,
        userId,
        method,
        reservationDateTime,
        completedAt,
        lastProcessingDate,
        stockStatus,
      };
    });
  }, [state.list]);

  return (
    <>
      <PageLabel
        optionComponent={
          <Button
            fillMode="outline"
            icon="check"
            style={{ height: 38 }}
            onClick={() => {
              dispatch(
                openModal({
                  visiable: true,
                  children: <ReceiveProductPopups />,
                  style: { padding: "25px 20px 15px 20px" },
                })
              );
            }}>
            제품 수령
          </Button>
        }>
        감정/등록 내역
      </PageLabel>
      <Filters
        defaultState={{
          ...getDefaultPageSize(),
          sortType: "DESC",
          dateType: "CREATED",
          total: 0,
          startDate: formatTime(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            ),
            "YYYY-MM-DD"
          ),
          endDate: formatTime(),
          selected: [],
        }}
        dateType="week"
        onSearch={fetchList}
        excel={onClickExcelDownload}
        data={[
          {
            label: "조회기간",
            type: "render",
            render: (
              <Flex row>
                <select
                  value={state?.dateType}
                  className={classes.selectBox}
                  onChange={(e) => {
                    handleValueChange("dateType", e.target.value);
                  }}>
                  <option value="CREATED">{"요청일"}</option>
                  <option value="COMPLETED">{"완료일"}</option>
                  <option value="STOCKED">{"입고일"}</option>
                  <option value="TOOK">{"수령일"}</option>
                </select>
                <input
                  value={state?.startDate || ""}
                  onChange={(e) => {
                    handleValueChange("startDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
                <span style={{ alignSelf: "center", margin: "0px 5px" }}>
                  ~
                </span>
                <input
                  value={state?.endDate || ""}
                  onChange={(e) => {
                    handleValueChange("endDate", e.target.value);
                  }}
                  type="date"
                  className={classes.datepicker}
                />
              </Flex>
            ),
          },
          {
            label: "접수번호",
            type: "input",
            key: "number",
            autoFocus: true,
          },
          {
            label: "회원검색",
            type: "input",
            placeholder: "고객명, 연락처, 고객번호",
            key: "user",
          },
          {
            label: "대리점검색",
            type: "input",
            placeholder: "대리점명,연락처",
            key: "shop",
          },
          {
            label: "자산",
            type: "menu",
            key: "assetType",
            renderWidth: "25%",
            data: [
              { label: "금", value: "GOLD" },
              { label: "은", value: "SILVER" },
            ],
          },
          {
            label: "진행상태",
            type: "menu",
            key: "status",
            renderWidth: "25%",
            data: [
              { label: "접수", value: "REQUEST" },
              { label: "접수취소", value: "CANCEL" },
              // { label: "접수승인", value: "접수승인" },
              // { label: "접수반려", value: "접수반려" },
              { label: "감정시작(감정중)", value: "VERIFY_START" },
              { label: "감정반려", value: "VERIFY_DENY" },
              { label: "승인대기", value: "CONFIRM_WAIT" },
              { label: "감정완료", value: "VERIFY_COMPLETE" },
              { label: "교환완료", value: "EXCHANGE_COMPLETE" },
              { label: "반송신청", value: "RETURN_REQUEST" },
              { label: "반송대기", value: "RETURN_WAIT" },
              { label: "반송완료", value: "RETURN_COMPLETE" },
            ],
          },
          {
            label: "접수",
            type: "menu",
            key: "method",
            renderWidth: "20%",
            data: [
              { label: "택배", value: "SIMPLE_DELIVERY" },
              { label: "내방", value: "SIMPLE_VISIT" },
              { label: "현장감정", value: "DIRECT_VISIT" },
              { label: "Biz 다이렉트", value: "GBGB_BIZ_DIRECT" },
              //   { label: "위탁거래", value: "위탁거래" },
            ],
          },
          {
            label: "제휴사",
            type: "menu",
            renderWidth: "20%",
            data: associateShops,
            key: "associateShopId",
          },
          {
            label: "입고상태",
            type: "checkbox",
            key: "stockStatus",
            data: [
              { label: "입고대기", value: "WAIT" },
              { label: "입고배송", value: "DELIVERY" },
              { label: "재고수령", value: "TAKE" },
              { label: "입고완료", value: "COMPLETE" },
              { label: "입고취소요청", value: "CANCEL_WAIT" },
            ],
          },
          // {
          //   label: "재고",
          //   type: "radio",
          //   key: "stockStatus",
          //   wrap: true,
          //   data: [
          //     { label: "입고", value: "입고" },
          //     { label: "미입고", value: "미입고" },
          //   ],
          // },
          /*
          {
            label: "재고 상태",
            type: "menu",
            key: "stockStatus",
            wrap: true,
            data: [
              { label: "입고", value: "입고" },
              { label: "미입고", value: "미입고" },
            ],
          },*/
        ].filter((x) => x)}
      />
      <Flex row className={classes.tableHeader}>
        <Flex row>
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            금 중량 합계 : {numFormat(goldWeight) || 0} g
          </Text>
          &nbsp;&nbsp;
          <Text font={fonts.notoSansKRBold} className={classes.label}>
            은 중량 합계 : {numFormat(silverWeight) || 0} g
          </Text>
          {isKorda ? (
            <>
              <Flex className={classes.statusCount} row>
                <span
                  className={`${
                    waitingStatus === "승인대기"
                      ? classes.linkStatusSelected
                      : classes.linkStatus
                  }`}>
                  승인대기 :
                  <span
                    onClick={() => {
                      setWaitingStatus("승인대기");
                      waitSearch("CONFIRM_WAIT");
                    }}>
                    {waitingApprovalCount}건
                  </span>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  className={`${
                    waitingStatus === "반송대기"
                      ? classes.linkStatusSelected
                      : classes.linkStatus
                  }`}>
                  반송대기 :
                  <span
                    name="반송대기"
                    onClick={() => {
                      setWaitingStatus("반송대기");
                      waitSearch("RETURN_WAIT");
                    }}>
                    {waitingReturnCount}건
                  </span>
                </span>
              </Flex>
            </>
          ) : (
            ""
          )}
        </Flex>
        <Flex row>
          <RadioGroup
            key={state.sortType}
            value={state.sortType}
            onChange={(e) => {
              onChange("sortType", e.target.value);
            }}
            row
            style={{ alignSelf: "flex-end", marginTop: 50 }}>
            <FormControlLabel
              label="최근 순서로"
              value="DESC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
            <FormControlLabel
              label="오래된 순서로"
              value="ASC"
              control={<Radio color="default" style={{ color: "black" }} />}
            />
          </RadioGroup>
        </Flex>
      </Flex>
      <DataTable
        name="감정현황"
        rows={[
          [
            { type: "no" },
            { label: "접수번호", key: "number" },
            { label: "요청일", key: "requestDate" },
            { label: "자산", key: "assetType" },
            { label: "감정 정보", key: "info" },
            { label: "고객", key: "customer" },
            { label: "고객번호", key: "userId" },
            { label: "제휴사명", key: "associateShopName" },
            {
              label: "접수 방법",
              type: "render",
              render: (item) => {
                return <span style={{ textAlign: "left" }}>{item.method}</span>;
              },
            },
            { label: "진행상태", key: "status" },
            { label: "입고상태", key: "stockStatus" },
            { label: "예약일시", key: "reservationDateTime" },
            { label: "완료일시", key: "completedAt" },
            { label: "최종 처리일시", key: "lastProcessingDate" },
            { label: "제품수령일", key: "takenAt", type: "date" },
            { label: "입고일", key: "stockedAt" },
            // { type: "check" },
            // {
            //   label: "testRender",
            //   type: "render",
            //   render: (x) => {
            //     return <div>{x.customer + x.customer}</div>;
            //   },
            // },
          ],
        ]}
        list={parseData}
        totalCount={state.total}
        onClick={(item) => {
          history.push(routes.appraisalStatusDetail, {
            id: item.id,
          });
        }}
      />
    </>
  );
}

const ReceiveProductPopups = () => {
  const [barCode, setBarCode] = useState("");
  const [barCodeList, setBarCodeList] = useState([]); //바코드 조회 리스트
  const [receiptList, setReceiptList] = useState([]); //수령 저장 보낼 리스트
  const [checkedBarCode, setCheckedBarCode] = useState("");
  const [tempBarCode, setTempBarCode] = useState("");
  const dispatch = useDispatch();
  const [idList, setIdList] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (barCode) {
      dispatch(loadingStart);
      APIS.getAppraisalInventoryTake(
        objToQueryStr({
          number: barCode,
        })
      )
        .then(({ data: { data, success, message } }) => {
          if (success) {
            if (data.length === 1) {
              const reformData = [...receiptList, ...data];
              reformData.reduce(function (acc, current) {
                if (acc.findIndex(({ id }) => id === current.id) === -1) {
                  acc.push(current);
                }
                setReceiptList(
                  acc.map((item, i) => {
                    return {
                      ...item,
                      index: i + 1,
                      selected: false,
                      stockStatus: item?.stockStatus
                        .replace("CANCEL_WAIT", "입고취소 요청")
                        .replace("WAIT", "입고대기")
                        .replace("DELIVERY", "입고배송")
                        .replace("TAKE", "재고수령")
                        .replace("COMPLETE", "입고완료"),
                    };
                  })
                );
                return acc;
              }, []);
              setBarCodeList([]);
            } else {
              setBarCodeList(
                data.map((item, i) => {
                  return {
                    ...item,
                    index: i + 1,
                    selected: false,
                    stockStatus: item?.stockStatus
                      .replace("CANCEL_WAIT", "입고취소 요청")
                      .replace("WAIT", "입고대기")
                      .replace("DELIVERY", "입고배송")
                      .replace("TAKE", "재고수령")
                      .replace("COMPLETE", "입고완료"),
                  };
                })
              );
            }
            setTimeout(() => {
              if (data.length === 1) {
                setTempBarCode("");
                setBarCode("");
              }
            }, 500);
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  }, [barCode]);

  const onSaveInventoryTake = () => {
    if (idList.length === 0) {
      dispatch(actionError("체크박스를 선택하여 저장해주시기 바랍니다."));
    } else {
      dispatch(loadingStart);
      APIS.patchAppraisalInventoryTake(idList)
        .then(({ data: { data, success, message } }) => {
          if (success) {
            const failFound = data.filter((e) => e.isSuccess === false);
            if (failFound.length > 0) {
              dispatch(
                actionOpen(
                  `수령 저장에 실패했습니다. (실패 ${failFound.length}건 / 총 ${idList.length}건)`,
                  null,
                  null,
                  null,
                  true
                )
              );
              setReceiptList(
                failFound.map((item, i) => {
                  return {
                    ...item,
                    index: i + 1,
                    selected: false,
                    stockStatus: item.stockStatus
                      .replace("CANCEL_WAIT", "입고취소 요청")
                      .replace("WAIT", "입고대기")
                      .replace("DELIVERY", "입고배송")
                      .replace("TAKE", "재고수령")
                      .replace("COMPLETE", "입고완료"),
                  };
                })
              );
            } else {
              dispatch(
                actionOpen("수령 저장했습니다.", null, null, null, true)
              );
              setReceiptList([]);
              setIdList([]);
            }
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  const handleClickSelect = () => {
    let checkList = [checkedBarCode];
    receiptList.forEach(({ number }) => {
      checkList = checkList.filter((checkedNumber) => number !== checkedNumber);
    });
    setReceiptList(
      [
        ...receiptList,
        ...barCodeList.filter(({ number }) => checkList.includes(number)),
      ].map((item, i) => {
        return {
          ...item,
          index: i + 1,
        };
      })
    );
    setCheckedBarCode("");
    setBarCodeList([]);
    setTempBarCode("");
    inputRef.current?.focus();
  };

  const handleChangeRadio = useCallback(
    (e) => {
      setCheckedBarCode(e.value);
    },
    [setCheckedBarCode]
  );
  return (
    <div
      style={{
        width: "1500px",
        height: "450px",
      }}>
      <div
        style={{
          fontFamily: fonts.notoSansKRBold,
          marginBottom: 10,
          fontSize: "larger",
        }}>
        제품수령
      </div>
      <div
        style={{
          marginBottom: 5,
          display: "flex",
          width: "100%",
          fontFamily: fonts.notoSansKRBold,
        }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "73%",
          }}>
          <div>총수령 건수 :{receiptList.length}</div>
          <div style={{ float: "right", marginTop: 5 }}>
            <button
              style={{ width: 70, fontWeight: "bold", marginLeft: 20 }}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={() => {
                onSaveInventoryTake();
              }}>
              수령 저장
            </button>
          </div>
        </div>
        <div style={{ width: "27%", textAlign: "right" }}>
          접수번호 조회{" "}
          <input
            ref={inputRef}
            style={{ width: 265, height: 34, borderRadius: 5 }}
            value={tempBarCode}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (tempBarCode.length < 4) {
                  dispatch(actionError("최소 4자리까지 입력해주세요"));
                } else {
                  setBarCode(e.target.value);
                }
              }
            }}
            onChange={(e) => {
              setBarCode("");
              if (!e.nativeEvent.data) {
                setTempBarCode(e.target.value);
              }
            }}
            onKeyDown={(e) => {
              setBarCode("");
              if (e.key.length === 1 && !e.altKey && !e.ctrlKey && !e.metaKey) {
                const eng = "rRseEfaqQtTdwWczxvgkoiOjpuPhynbml";
                const kor =
                  "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎㅏㅐㅑㅒㅓㅔㅕㅖㅗㅛㅜㅠㅡㅣ";
                const korIndex = kor.indexOf(e.key);

                setTempBarCode((text) =>
                  (text + (eng[korIndex] || e.key)).toLowerCase()
                );
              }
            }}
            autoFocus
          />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "75%" }}>
          <GridTable
            style={{ height: "350px" }}
            data={receiptList}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
            }}
            name={gridName}
            scrollable
            reorderable>
            <Column
              title=""
              width="40px"
              field={SELECTED_FIELD}
              headerCell={() => {
                const filterData = receiptList.filter(
                  (item) => item.failMessage === null
                );
                return (
                  <div>
                    <Checkbox
                      checked={
                        filterData.length === idList.length &&
                        filterData.length !== 0
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        const { ariaChecked } = e.syntheticEvent.target;
                        if (ariaChecked === "true") {
                          setIdList([]);
                        } else {
                          setIdList([...filterData.map((item) => item.number)]);
                        }
                      }}
                    />
                  </div>
                );
              }}
              cell={(props) => {
                const { dataItem } = props;
                const data = dataItem["number"];
                const failData = dataItem["failMessage"];
                return (
                  <td style={{ textAlign: "center" }}>
                    <Checkbox
                      checked={idList.includes(data) ? true : false}
                      onChange={() => {
                        let parseList = cloneDeep(idList);
                        if (idList.includes(data)) {
                          const result = parseList.filter(
                            (item) => item !== data
                          );
                          setIdList(result);
                        } else {
                          parseList.push(data);
                          setIdList(parseList);
                        }
                      }}
                      disabled={failData ? true : false}
                    />
                  </td>
                );
              }}
            />
            <Column field="index" width="60px" title="No" cell={centerCell} />
            <Column
              field="number"
              title="접수번호(바코드)"
              width="220px"
              cell={(props) => {
                const { dataItem } = props;
                return <td className="td-c">{dataItem["number"]}</td>;
              }}
            />
            <Column
              field="completedAt"
              width="120px"
              title="요청일"
              cell={centerCell}
            />
            <Column
              field="shopName"
              width="130px"
              title="접수대리점"
              cell={centerCell}
            />
            <Column
              field="status"
              width="100px"
              title="교환상태"
              cell={centerCell}
            />
            <Column
              field="stockStatus"
              width="100px"
              title="재고상태"
              cell={centerCell}
            />
            <Column
              field="failMessage"
              width="310px"
              title="메시지"
              cell={(props) => {
                const { dataItem } = props;
                return (
                  <td style={{ color: "red" }}>{dataItem["failMessage"]}</td>
                );
              }}
            />
          </GridTable>
        </div>
        <Flex style={{ justifyContent: "center", padding: 8 }}>
          <Button
            fillMode="outline"
            icon="arrow-chevron-left"
            style={{ width: 30 }}
            onClick={handleClickSelect}
          />
        </Flex>
        <div style={{ width: "25%" }}>
          <GridTable
            style={{ height: "350px" }}
            data={barCodeList}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
              enabled: true,
              drag: false,
              cell: false,
            }}
            scrollable>
            <Column
              width="40px"
              cell={(props) => {
                const { dataItem } = props;
                const data = dataItem["number"];
                return (
                  <td>
                    <RadioButton
                      value={data}
                      checked={checkedBarCode === data ? true : false}
                      onChange={handleChangeRadio}
                    />
                  </td>
                );
              }}
            />
            <Column
              field="number"
              title="접수번호(바코드)"
              width="200px"
              cell={(props) => {
                const { dataItem } = props;
                return <td className="td-c">{dataItem["number"]}</td>;
              }}
            />
            <Column
              field="stockStatus"
              width="100px"
              title="재고상태"
              cell={centerCell}
            />
          </GridTable>
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  tableHeader: {
    alignSelf: "stretch",
    justifyContent: "space-between",
    margin: "10px 50px",
    alignItems: "flex-end",
  },
  label: {
    marginBottom: "10px",
    marginRight: "15px",
    "& span": { fontSize: 14, color: "#444" },
  },
  datepicker: {
    fontFamily: fonts.notoSansKRMedium,
    fontSize: "15px",
    padding: "10px 16px",
    border: "1px solid rgba(0,0,0,0.23)",
    maxWidth: "176px",
  },
  selectBox: {
    minWidth: "117px !important",
    fontSize: 16,
    marginRight: 10,
  },
  statusCount: {
    flexDirection: "row",
    fontWeight: "bold",
    height: 29,
    alignItems: "center",
    marginLeft: 30,
  },
  linkStatus: {
    "& span": {
      color: "blue",
    },
    cursor: "pointer",
  },
  linkStatusSelected: {
    "& span": {
      color: "brown",
    },
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});

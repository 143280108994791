import { useState, useEffect, useMemo } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import GridTable, {
  NumberCell,
  CenterCell,
} from "components/gird-table/GridTable";
import withProgressBar from "hoc/withProgressBar";
import getStockStatus from "hook/getStockStatus";

const gridName = "StockStatus";

/**
 * 재고현황
 */
const StockStatus = withProgressBar(({ progressBar, refresh }) => {
  const [statusList, setStatusList] = useState([]);

  const fetchList = () => {
    progressBar.on();
    getStockStatus()
      .then((list) => {
        const updateList = list.map((item) => {
          const decimalPlaces = item.goldOrSilver === "GOLD" ? 1000 : 10;
          return {
            ...item,
            appraisalFee:
              Math.floor(item.appraisalFee * decimalPlaces) / decimalPlaces,
            incomeBalance:
              Math.floor(item.incomeBalance * decimalPlaces) / decimalPlaces,
          };
        });
        setStatusList(updateList);
      })
      .finally(progressBar.off);
  };

  useEffect(() => {
    if (refresh) {
      fetchList();
    }
  }, [refresh]);

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <GridTable
      className="inventory-status-grid"
      name={gridName}
      data={statusList}>
      <GridColumn title="금/은" field="goldOrSilver" cell={CenterCell} />
      <GridColumn title="관리재고">
        <GridColumn
          title="부분합"
          field="partialSum"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="상품(입고대기,입고완료)"
          field="goods"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="상품(포장중)"
          field="goodsPacking"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="원재료"
          field="raws"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="정련대기"
          field="refineWait"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="정련중"
          field="refining"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="제작중"
          field="making"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="미출고"
          field="nonDelivery"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
      </GridColumn>
      <GridColumn
        title="미입고"
        field="nonAppraisalStocked"
        cell={(props) => {
          return <NumberCell {...props} unit=" g" />;
        }}
      />
      <GridColumn title="손익">
        <GridColumn
          title="손익"
          field="income"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="처분예정"
          field="appraisalFee"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
        <GridColumn
          title="잔량"
          field="incomeBalance"
          cell={(props) => {
            return <NumberCell {...props} unit=" g" />;
          }}
        />
      </GridColumn>
    </GridTable>
  );
});

export default StockStatus;

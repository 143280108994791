import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import GridTable, {
  CenterCell,
  centerCell,
  numberWithCommasCell,
} from "../../components/gird-table/GridTable";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox, RadioButton } from "@progress/kendo-react-inputs";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import moment from "moment";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import { Link } from "react-router-dom";
import routes from "../../libs/routes";
import { FcRefresh } from "react-icons/fc";
import { openDialog } from "../../redux/dialog-alert/DialogAlertReducer";
import { numberWithCommas, formatTime } from "../../libs/utils";
import { useHistory, useLocation } from "react-router";
import { getDefaultPageSize, STORAGE_KEY } from "../../libs/consts";
import useFocusAndBlur from "../../hook/useFocusBlur";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const gridName = "TransactionIntegratedHistory";

const assetCheckBox = [
  { title: "금", key: "GOLD" },
  { title: "은", key: "SILVER" },
  { title: "KRW", key: "KRW" },
  { title: "포인트", key: "POINT" },
];

const tradeCheckBox = [
  { title: "입금", key: "DEPOSIT" },
  { title: "출금", key: "WITHDRAW" },
  { title: "매수", key: "BUY" },
  { title: "매도", key: "SELL" },
  { title: "감정평가", key: "APPRAISAL" },
  { title: "실물인출", key: "GOODS" },
  { title: "안심 구매", key: "SAFE_TRADE_BUY" },
  { title: "안심 판매", key: "SAFE_TRADE_SELL" },
  { title: "안심구매 철회", key: "SAFE_TRADE_BUY_PENALTY" },
  { title: "안심판매 철회", key: "SAFE_TRADE_SELL_PENALTY" },
  { title: "안심 감정", key: "SAFE_TRADE_AUTH_PRICE" },
  { title: "이벤트", key: "EVENT" },
  { title: "포인트 소멸", key: "POINT_EXTINCT" },
  { title: "자산 보냄", key: "SEND_ASSET" },
  { title: "자산 받음", key: "RECEIVE_ASSET" },
  { title: "보관료", key: "STORAGE_FEE" },
  { title: "감정평가 수수료", key: "APPRAISAL_FEE" },
];

const marketList = [
  { text: "전체", key: "ALL" },
  { text: "추천대리점", key: "recommendShopName" },
  { text: "입출고대리점", key: "inOutShopName" },
];

const checkList = [
  { text: "전체", key: "ALL" },
  { text: "현금영수증", key: "CASH" },
  { text: "자진발행", key: "SELF" },
];

const tradePlaceList = [
  { label: "전체", value: "ALL" },
  { label: "앱", value: "APP" },
  { label: "몰", value: "MALL" },
  { label: "K-Bank", value: "K_BANK_MALL" },
  { label: "Biz", value: "BIZ" },
];

const addTradeTypeLists = [
  { label: "전체", value: "ALL" },
  { label: "가상", value: "VIRTUAL" },
  { label: "헥토 간편", value: "HECTO_SIMPLE" },
  { label: "토스 퀵계좌", value: "TOSS_QUICK" },
  { label: "K-Bank", value: "K_BANK_MALL" },
];

const INIT = {
  userKeyword: "",
  from: moment().subtract(7, "d").format("YYYY-MM-DD"),
  to: moment(new Date()).format("YYYY-MM-DD"),
  assetType: [],
  tradeType: [],
  salesReportTypeList: checkList[0],
  market: marketList[0],
  marketName: "",
  shopName: "",
  addTradeTypeList: [], // 입금방식
  sort: "DESC",
};

const TransactionIntegratedHistory = () => {
  const location = useLocation();
  const [data, setData] = useState(
    location.state?.data ? location.state?.data : []
  );
  const [expanded, setExpanded] = useState(true);
  const [page, setPage] = useState(
    location.state?.page
      ? { ...location.state?.page }
      : {
          ...getDefaultPageSize(),
          totalElements: 0,
        }
  );
  const [isCheck, setCheck] = useState(false);
  const [range, setRange] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [condition, setCondition] = useState(
    location.state?.condition
      ? location.state?.condition
      : {
          userKeyword: "",
          from: moment().subtract(7, "d").format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
          assetType: [],
          tradeType: [],
          salesReportTypeList: checkList[0],
          market: marketList[0],
          marketName: "",
          shopName: "",
          sort: "DESC",
          tradePlace: "ALL",
          addTradeTypeList: [],
        }
  );
  const dispatch = useDispatch();
  const firstRenderRef = useRef(false);
  const inputRef = useRef(null);
  const history = useHistory();
  const { handleFocus, handleBlur } = useFocusAndBlur();

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    if (!firstRenderRef.current) {
      inputRef.current?.focus();
      firstRenderRef.current = true;
    } else {
      fetchList({ page: 0, size: page.size });
    }
  }, [range]);

  useEffect(() => {
    handleValueChange("condition", condition);
    if (condition?.tradeType?.filter((item) => item === "EVENT").length > 0) {
      if (eventList.length === 0) {
        fetchEventList();
      } else if (!condition?.eventId) {
        setCondition({
          ...condition,
          eventId: eventList[0],
        });
      }
    }
  }, [condition]);

  useEffect(() => {
    handleValueChange("page", page);
  }, [page]);

  useEffect(() => {
    handleValueChange("data", data);
  }, [data]);

  const onChangeValue = (e) => {
    const { value, name } = e.target;
    setCondition({
      ...condition,
      [name]: value,
    });
  };

  const onChangeSort = (e) => {
    const { value } = e;
    setCondition({
      ...condition,
      sort: value,
    });
    if (data.length > 0) {
      fetchList({ page: 0, size: page.size }, value);
    }
  };

  const fetchList = (pageData, sort) => {
    const parseToQuery = (data) => {
      let query = "";
      let key = Object.keys(data);
      key = key.filter((item) => data[item] !== "" || data[item].length > 0);
      key.forEach((item) => {
        if (Array.isArray(data[item]) && data[item].length > 0) {
          if (
            item === "tradeType" &&
            condition.tradeType.length < tradeCheckBox.length
          ) {
            condition.tradeType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          } else if (
            item === "assetType" &&
            condition.assetType.length < assetCheckBox.length
          ) {
            condition.assetType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          } else if (
            item === "addTradeTypeList" &&
            condition.addTradeTypeList.length < addTradeTypeLists.length
          ) {
            condition.addTradeTypeList.forEach((el) => {
              query += `${item}=${el}&`;
            });
          }
        } else {
          if (item === "market") {
            if (data[item].key !== "ALL" && condition.marketName) {
              query += `${data[item].key}=${condition.marketName}&`;
            }
          } else if (item === "salesReportTypeList") {
            if (data[item].key !== "ALL") {
              query += `${item}=${data[item].key}&`;
            }
          } else if (item === "eventId") {
            if (data[item].eventName !== "전체") {
              query += `${item}=${data[item].eventId}&`;
            }
          } else if (item === "sort") {
            query += `${item}=${sort ? sort : condition.sort}&`;
          } else {
            if (data[item] !== "ALL" && !Array.isArray(data[item])) {
              if (item !== "marketName") {
                query += `${item}=${data[item]}&`;
              }
            }
          }
        }
      });

      if (query.endsWith("&")) {
        query = query.substring(0, query.lastIndexOf("&"));
      }
      return `?${query}`;
    };

    if (!(condition.tradeType.filter((item) => item === "EVENT").length > 0)) {
      delete condition["eventId"];
    }
    if (condition.from === "Invalid date" || condition.to === "Invalid date") {
      dispatch(actionError("검색기간의 값을 빠짐없이 입력하여 주세요."));
    } else {
      dispatch(loadingStart);
      const parsePage = pageData
        ? pageData
        : {
            page: page.page,
            size: page.size,
          };
      const queryString = parseToQuery({ ...condition, ...parsePage });
      APIS.getIntegrationHistory(queryString)
        .then((res) => {
          const { data } = res;
          const { success, data: historyData } = data;
          const { content, totalElements, number, size } = historyData;
          if (success) {
            setData(content || []);
            setPage({
              ...page,
              totalElements,
              page: number * size,
              size: size,
            });
          } else {
            dispatch(loadingEnd);
          }
        })
        .catch((err) => dispatch(actionError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  };

  const fetchEventList = () => {
    APIS.getEventList()
      .then((res) => {
        const { data } = res;
        const { success, data: eventData, message } = data;
        const parseEvent = eventData.map((item) => {
          return {
            ...item,
            eventName: `[${item.eventId}] ${item.eventName}`,
          };
        });
        const parseData = [{ eventId: " ", eventName: "전체" }, ...parseEvent];
        if (success) {
          setEventList(parseData);
          setCondition({
            ...condition,
            eventId: parseData[0],
          });
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
  };

  const onClickSearch = () => {
    if (!isCheck) {
      setCheck(true);
    }
    fetchList({ page: 0, size: page.size });
  };

  const onClickDownload = () => {
    const parseToQuery = (data) => {
      let query = "";
      let key = Object.keys(data);
      key = key.filter((item) => data[item] !== "" || data[item].length > 0);
      key.forEach((item) => {
        if (Array.isArray(data[item]) && data[item].length > 0) {
          if (
            item === "tradeType" &&
            condition.tradeType.length < tradeCheckBox.length
          ) {
            condition.tradeType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          } else if (
            item === "assetType" &&
            condition.assetType.length < assetCheckBox.length
          ) {
            condition.assetType.forEach((el) => {
              query += `${item}=${el}&`;
            });
          }
        } else {
          if (item === "market") {
            if (data[item].key !== "ALL" && condition.marketName) {
              query += `${data[item].key}=${condition.marketName}&`;
            }
          } else if (item === "salesReportTypeList") {
            if (data[item].key !== "ALL") {
              query += `${item}=${data[item].key}&`;
            }
          } else if (item === "eventId") {
            if (data[item].eventName !== "전체") {
              query += `${item}=${data[item].eventId}&`;
            }
          } else if (item === "sort") {
            query += `${item}=${data[item] ? data[item] : condition.sort}&`;
          } else {
            if (data[item] !== "ALL" && !Array.isArray(data[item])) {
              if (item !== "marketName") {
                query += `${item}=${data[item]}&`;
              }
            }
          }
        }
      });

      if (query.endsWith("&")) {
        query = query.substring(0, query.lastIndexOf("&"));
      }
      return `?${query}`;
    };

    dispatch(loadingStart);
    if (!(condition.tradeType.filter((item) => item === "EVENT").length > 0)) {
      delete condition["eventId"];
    }
    const queryString = parseToQuery({ ...condition });
    APIS.getIntegrationHistoryDownload(queryString)
      .then((res) => {
        const { data } = res;
        const blob = new Blob([data]);
        saveAs(
          blob,
          `통합거래내역_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const onPageChange = (event) => {
    const { page: pageData } = event;
    const { skip, take } = pageData;
    localStorage.setItem(STORAGE_KEY.tableSize, take);
    fetchList({ page: skip / take, size: take });
  };

  const onClickRefresh = () => {
    dispatch(
      openDialog({
        action: () => refreshGrid(),
        message: "그리드 설정을 초기화 하시겠습니까?",
      })
    );
  };

  const refreshGrid = () => {
    let setting = JSON.parse(localStorage.getItem("kumbang-gird-column"));
    let order = JSON.parse(localStorage.getItem("kumbang-grid-order"));
    if (setting?.[gridName]) {
      delete setting[gridName];
    }

    if (order?.[gridName]) {
      delete order[gridName];
    }

    localStorage.setItem("kumbang-gird-column", JSON.stringify(setting));
    localStorage.setItem("kumbang-grid-order", JSON.stringify(order));
    window.location.reload();
  };

  const onClickDay = (e) => {
    const { name } = e.target;
    switch (name) {
      case "day":
        setCondition({
          ...condition,
          from: moment(new Date()).format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "week":
        setCondition({
          ...condition,
          from: moment().subtract(1, "week").format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "1m":
        setCondition({
          ...condition,
          from: moment().subtract(1, "months").format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "3m":
        setCondition({
          ...condition,
          from: moment().subtract(3, "months").format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
      case "6m":
        setCondition({
          ...condition,
          from: moment().subtract(6, "months").format("YYYY-MM-DD"),
          to: moment(new Date()).format("YYYY-MM-DD"),
        });
        setRange(!range);
        break;
    }
  };

  const {
    userKeyword,
    from,
    to,
    assetType,
    tradeType,
    market,
    marketName,
    salesReportTypeList,
    eventId,
    tradePlace,
    addTradeTypeList,
  } = condition;

  // 거래매체 선택
  const handleTradePlace = (type) => {
    setCondition({ ...condition, tradePlace: type.value });
  };

  return (
    <HistoryWrap>
      <TitleWrap onClick={() => setExpanded(!expanded)}>
        <Title>통합거래내역</Title>
        <div>
          <span style={{ marginRight: "20px" }}>
            {expanded ? <AiOutlineUp /> : <AiOutlineDown />}
          </span>
        </div>
      </TitleWrap>
      <FilterWrap expanded={expanded}>
        <Table>
          <tbody>
            <tr>
              <th id="search-th">회원검색</th>
              <td colSpan={1}>
                <Input
                  name="userKeyword"
                  type="search"
                  style={{ margin: "10px" }}
                  value={userKeyword}
                  ref={inputRef}
                  onChange={onChangeValue}
                  placeholder="이름, 고객번호, 전화번호"
                  onFocus={() => handleFocus("search-th")}
                  onBlur={() => handleBlur("search-th")}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onClickSearch();
                    }
                  }}
                />
              </td>
              <th id="date-from-th">검색기간</th>
              <td colSpan={5}>
                <div
                  style={{
                    widows: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                    justifyContent: "flex-start",
                  }}>
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-from-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date(moment().subtract(1, "week"))}
                      value={from !== "Invalid date" ? new Date(from) : ""}
                      max={new Date()}
                      onFocus={() => handleFocus("date-from-th")}
                      onBlur={() => handleBlur("date-from-th")}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          from: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                  &nbsp; &nbsp;
                  <span>-</span>
                  &nbsp; &nbsp;
                  <div style={{ position: "relative" }}>
                    <DatePicker
                      id="search-to-date"
                      placeholder="날짜를 선택해주세요"
                      format={"yyyy-MM-dd"}
                      defaultValue={new Date()}
                      value={to !== "Invalid date" ? new Date(to) : ""}
                      max={new Date()}
                      min={new Date(from)}
                      onFocus={() => handleFocus("date-from-th")}
                      onBlur={() => handleBlur("date-from-th")}
                      onChange={(event) => {
                        const { value } = event;
                        setCondition({
                          ...condition,
                          to: moment(value).format("YYYY-MM-DD"),
                        });
                        document.getElementById(
                          "date-from-th"
                        ).style.transition = "all .4s ease-in-out";
                        document.getElementById(
                          "date-from-th"
                        ).style.background = "#f2f4f6";
                        document.getElementById("date-from-th").style.color =
                          "#636a75";
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "400px",
                      height: "100%",
                      marginLeft: "10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}>
                    <DayBtn name="day" onClick={onClickDay}>
                      당일
                    </DayBtn>
                    <DayBtn name="week" onClick={onClickDay}>
                      1주일
                    </DayBtn>
                    <DayBtn name="1m" onClick={onClickDay}>
                      1개월
                    </DayBtn>
                    <DayBtn name="3m" onClick={onClickDay}>
                      3개월
                    </DayBtn>
                    <DayBtn name="6m" onClick={onClickDay}>
                      6개월
                    </DayBtn>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th id="shop-th">대리점</th>
              <td colSpan={1}>
                <Input
                  value={condition.shopName}
                  placeholder="대리점명을 입력해주세요."
                  style={{ margin: 10 }}
                  onChange={(e) => {
                    setCondition({
                      ...condition,
                      shopName: e.target.value,
                    });
                  }}
                  onFocus={() => handleFocus("shop-th")}
                  onBlur={() => handleBlur("shop-th")}
                />
              </td>
              <th id="asset-th">자산유형</th>
              <td>
                <AssetCheckBoxWrap>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                    }}>
                    <label>
                      <Checkbox
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "3px",
                          borderRadius: "0.5rem",
                          border: "1px solid #bebebe",
                        }}
                        name={"ALL"}
                        checked={
                          assetType.length === assetCheckBox.length
                            ? true
                            : false
                        }
                        onFocus={() => handleFocus("asset-th")}
                        onBlur={() => handleBlur("asset-th")}
                        onChange={({ target }) => {
                          const { name } = target;
                          let arr = [...assetType];
                          if (name === "ALL") {
                            if (arr.length === assetCheckBox.length) {
                              arr = [];
                            } else {
                              arr = [
                                ...assetCheckBox.map((item) => {
                                  return item.key;
                                }),
                              ];
                            }
                            setCondition({
                              ...condition,
                              assetType: arr,
                            });
                          }
                        }}
                      />
                      <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                        전체
                      </span>
                    </label>
                  </div>
                  {assetCheckBox.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "3px",
                        }}
                        key={`assetCheckbox_${index}`}>
                        <label>
                          <Checkbox
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "3px",
                              borderRadius: "0.5rem",
                              border: "1px solid #bebebe",
                            }}
                            name={item.key}
                            checked={assetType.includes(item.key)}
                            onFocus={() => handleFocus("asset-th")}
                            onBlur={() => handleBlur("asset-th")}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...assetType];
                              if (name === "ALL") {
                                if (arr.length === assetCheckBox.length) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...assetCheckBox.map((item) => {
                                      return item.key;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                setCondition({
                                  ...condition,
                                  assetType: arr,
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                            {item.title}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </AssetCheckBoxWrap>
              </td>
            </tr>
            <tr>
              <th id="trading-th">거래매체</th>
              <td>
                <GoodsTradeTypeWrap>
                  {tradePlaceList.map((type, index) => {
                    return (
                      <TradeRadioButton
                        key={`tradePlace-${index}`}
                        value={type.value}
                        checked={type.value === tradePlace}
                        label={type.label}
                        onChange={handleTradePlace}
                        onFocus={() => handleFocus("trading-th")}
                        onBlur={() => handleBlur("trading-th")}
                      />
                    );
                  })}
                </GoodsTradeTypeWrap>
              </td>
              <th id="deposit-th">입금방식</th>
              <td>
                <TradeTypeCheckBoxWrap>
                  {addTradeTypeLists.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "5px",
                        }}
                        key={`addTradeTypeLists_${index}`}>
                        <label>
                          <Checkbox
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "3px",
                              borderRadius: "0.5rem",
                              border: "1px solid #bebebe",
                            }}
                            name={item.value}
                            checked={
                              addTradeTypeList?.includes(item.value)
                                ? true
                                : false
                            }
                            onFocus={() => handleFocus("deposit-th")}
                            onBlur={() => handleBlur("deposit-th")}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...addTradeTypeList];
                              if (name === "ALL") {
                                if (arr.length === addTradeTypeLists.length) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...addTradeTypeLists.map((item) => {
                                      return item.value;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  addTradeTypeList: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                if (
                                  addTradeTypeList.length ===
                                  addTradeTypeLists.length
                                ) {
                                  arr = arr.filter((item) => item !== "ALL");
                                }
                                setCondition({
                                  ...condition,
                                  addTradeTypeList: arr,
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                            {item.label}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </TradeTypeCheckBoxWrap>
              </td>
              <th id="calc-th">정산여부</th>
              <td>
                <div style={{ margin: "5px" }}>
                  <DropDownList
                    value={salesReportTypeList}
                    data={checkList}
                    dataItemKey="key"
                    textField="text"
                    style={{
                      width: "120px",
                      background: "#fff",
                    }}
                    onFocus={() => handleFocus("calc-th")}
                    onBlur={() => handleBlur("calc-th")}
                    onChange={(e) => {
                      const { value } = e;
                      setCondition({
                        ...condition,
                        salesReportTypeList: value,
                      });
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th id="trade-th">거래형태</th>
              <td colSpan={8}>
                <TradeTypeCheckBoxWrap>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "5px",
                    }}>
                    <label>
                      <Checkbox
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "3px",
                          borderRadius: "0.5rem",
                          border: "1px solid #bebebe",
                        }}
                        name={"ALL"}
                        checked={
                          tradeType.length === tradeCheckBox.length
                            ? true
                            : false
                        }
                        onFocus={() => handleFocus("trade-th")}
                        onBlur={() => handleBlur("trade-th")}
                        onChange={({ target }) => {
                          const { name } = target;
                          let arr = [...tradeType];
                          if (name === "ALL") {
                            if (arr.length === tradeCheckBox.length) {
                              arr = [];
                            } else {
                              arr = [
                                ...tradeCheckBox.map((item) => {
                                  return item.key;
                                }),
                              ];
                            }
                            setCondition({
                              ...condition,
                              tradeType: arr,
                            });
                          }
                        }}
                      />
                      <span style={{ fontSize: "14px", marginLeft: "2px" }}>
                        전체
                      </span>
                    </label>
                  </div>
                  {tradeCheckBox.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "5px",
                        }}
                        key={`tradeCheckBox_${index}`}>
                        <label>
                          <Checkbox
                            style={{
                              width: "20px",
                              height: "20px",
                              margin: "3px",
                              borderRadius: "0.5rem",
                              border: "1px solid #bebebe",
                            }}
                            name={item.key}
                            checked={
                              tradeType?.includes(item.key) ? true : false
                            }
                            onFocus={() => handleFocus("trade-th")}
                            onBlur={() => handleBlur("trade-th")}
                            onChange={({ target }) => {
                              const { name } = target;
                              let arr = [...tradeType];
                              if (name === "ALL") {
                                if (arr.length === tradeCheckBox.length) {
                                  arr = [];
                                } else {
                                  arr = [
                                    ...tradeCheckBox.map((item) => {
                                      return item.key;
                                    }),
                                  ];
                                }
                                setCondition({
                                  ...condition,
                                  tradeType: arr,
                                });
                              } else {
                                if (arr.includes(name)) {
                                  arr = arr.filter((item) => item !== name);
                                } else {
                                  arr.push(name);
                                }
                                setCondition({
                                  ...condition,
                                  tradeType: arr,
                                });
                              }
                            }}
                          />
                          <span style={{ fontSize: "13px", marginLeft: "2px" }}>
                            {item.title}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                </TradeTypeCheckBoxWrap>
              </td>
            </tr>
            <tr>
              {condition.tradeType.filter((item) => item === "EVENT").length >
                0 && (
                <>
                  <th id="event-th">이벤트명</th>
                  <td>
                    <div style={{ width: "200px", marginLeft: 5 }}>
                      <DropDownList
                        value={eventId}
                        data={eventList}
                        dataItemKey="eventId"
                        textField="eventName"
                        style={{
                          width: "280px",
                          height: "35px",
                          background: "#fff",
                        }}
                        onFocus={() => handleFocus("event-th")}
                        onBlur={() => handleBlur("event-th")}
                        onChange={(e) => {
                          const { value } = e;
                          setCondition({
                            ...condition,
                            eventId: value,
                          });
                        }}
                      />
                    </div>
                  </td>
                </>
              )}
            </tr>
            <tr>
              <td colSpan={8}>
                <ButtonWrap>
                  <SearchBtn onClick={onClickSearch}>검색</SearchBtn>
                  <InitBtn
                    onClick={() => {
                      setCondition({ ...INIT });
                    }}>
                    초기화
                  </InitBtn>
                  <RefreshBtn onClick={onClickRefresh}>
                    <FcRefresh style={{ fontSize: "20px" }} />
                  </RefreshBtn>
                  <ExcelBtn onClick={onClickDownload}>Excel</ExcelBtn>
                </ButtonWrap>
              </td>
            </tr>
          </tbody>
        </Table>
      </FilterWrap>
      <div
        style={{
          width: "100%",
          height: "15px",
          display: "flex",
          margin: "5px",
          marginBottom: "10px",
          alignItems: "center",
        }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "10px",
          }}>
          <div>
            <RadioButton
              label="최근 순서로"
              value="DESC"
              onChange={onChangeSort}
              checked={condition.sort === "DESC" ? true : false}
            />
          </div>
          &nbsp; &nbsp;
          <div>
            <RadioButton
              label="오래된 순서로"
              value="ASC"
              onChange={onChangeSort}
              checked={condition.sort === "ASC" ? true : false}
            />
          </div>
        </div>
      </div>
      {/* 검색 결과 */}
      <ListWrap expanded={expanded}>
        <GridTable
          name={gridName}
          style={{
            height: "100%",
          }}
          selectable={true}
          data={data}
          scrollable={true}
          skip={page.page}
          take={page.size}
          total={page.totalElements}
          pageable
          reorderable
          onPageChange={onPageChange}>
          {/* <Column title="NO" width={70} cell={centerCell} field="rawId" /> */}
          <Column
            title="거래일시"
            width={180}
            locked={true}
            cell={({ dataItem }) => {
              return (
                <td
                  className="left k-grid-content-sticky"
                  style={{ textAlign: "center", left: "0px", right: "0px" }}>
                  {dataItem?.["completedAt"] || "-"}
                </td>
              );
            }}
            field="completedAt"
          />
          <Column
            title="고객번호"
            width={70}
            field="userId"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td
                  style={{
                    textAlign: "center",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "default",
                  }}>
                  <Link to={`${routes.memberDetail}/${dataItem["userId"]}`}>
                    {dataItem?.["userId"] || "-"}
                  </Link>
                </td>
              );
            }}
          />
          <Column
            title="이름"
            width={70}
            field="userName"
            cell={(props) => {
              const { dataItem } = props;
              return (
                <td
                  style={{
                    textAlign: "center",
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "default",
                  }}>
                  <Link to={`${routes.memberDetail}/${dataItem["userId"]}`}>
                    {dataItem?.["userName"] || "-"}
                  </Link>
                </td>
              );
            }}
          />
          <Column
            title="휴대폰"
            width={120}
            field="userPhoneNumber"
            cell={CenterCell}
          />
          <Column
            title="거래 형태"
            width={80}
            cell={CenterCell}
            field="tradeType"
          />
          <Column
            title="거래 매체"
            width={70}
            cell={CenterCell}
            field="tradePlace"
          />
          <Column
            title="주문방식"
            width={80}
            cell={({ dataItem }) => {
              return (
                <td style={{ textAlign: "center" }}>
                  {dataItem["addTradeType"]?.replace(
                    "상속",
                    `상속(${dataItem["yourName"]})`
                  ) || "-"}
                </td>
              );
            }}
          />
          <Column
            title="자산"
            width={70}
            cell={({ dataItem }) => {
              return (
                <td style={{ textAlign: "center" }}>
                  {dataItem?.["assetType"] || "-"}
                </td>
              );
            }}
            field="assetType"
          />
          <Column
            title="주문금액"
            width={100}
            field="pureTradeKrw"
            cell={numberWithCommasCell}
          />
          <Column
            title="포인트"
            width={120}
            cell={numberWithCommasCell}
            field="tradePoint"
          />
          <Column
            title="수수료"
            width={100}
            field="userFee"
            cell={numberWithCommasCell}
          />
          <Column
            title="결제총액"
            width={100}
            field="totalPrice"
            cell={numberWithCommasCell}
          />
          <Column
            title="체결단가"
            width={100}
            field="pricePerGram"
            cell={numberWithCommasCell}
          />
          <Column
            title="결제중량"
            width={100}
            field="tradeGram"
            cell={({ dataItem }) => {
              return (
                <td style={{ textAlign: "right" }}>
                  {numberWithCommas(dataItem["tradeGram"])}
                </td>
              );
            }}
          />
          <Column title="자산 잔고">
            <Column
              title="KRW"
              width={130}
              field="afterKrw"
              reorderable={false}
              resizable={false}
              cell={numberWithCommasCell}
            />
            <Column
              title="포인트"
              width={120}
              field="afterPoint"
              cell={numberWithCommasCell}
            />
            <Column
              title="금"
              width={120}
              field="afterGold"
              reorderable={false}
              resizable={false}
              cell={numberWithCommasCell}
            />
            <Column
              title="은"
              width={120}
              field="afterSilver"
              reorderable={false}
              resizable={false}
              cell={numberWithCommasCell}
            />
          </Column>
          <Column title="대리점수수료">
            <Column
              title="추천대리점"
              width={120}
              field="recommendShopName"
              reorderable={false}
              resizable={false}
              cell={centerCell}
            />
            <Column
              width={100}
              reorderable={false}
              resizable={false}
              headerCell={() => {
                return (
                  <span>
                    추천
                    <br />
                    수수료
                  </span>
                );
              }}
              field="recommendShopFee"
              cell={numberWithCommasCell}
            />
            <Column
              title="이용대리점"
              width={120}
              field="usingShopName"
              reorderable={false}
              resizable={false}
              cell={centerCell}
            />
            <Column
              width={100}
              reorderable={false}
              resizable={false}
              headerCell={() => {
                return (
                  <span>
                    이용
                    <br />
                    수수료
                  </span>
                );
              }}
              field="usingShopFee"
              cell={numberWithCommasCell}
            />
            <Column
              title="제휴사"
              width={120}
              field="associateShopName"
              reorderable={false}
              resizable={false}
              cell={centerCell}
            />
            <Column
              width={100}
              reorderable={false}
              resizable={false}
              headerCell={() => {
                return (
                  <span>
                    제휴사
                    <br />
                    수수료
                  </span>
                );
              }}
              field="associateShopFee"
              cell={numberWithCommasCell}
            />
            <Column
              width={120}
              reorderable={false}
              resizable={false}
              headerCell={() => {
                return (
                  <span>
                    입출고
                    <br />
                    대리점
                  </span>
                );
              }}
              field="inOutShopName"
              cell={centerCell}
            />
            <Column
              width={100}
              reorderable={false}
              resizable={false}
              headerCell={() => {
                return (
                  <span style={{ textAlign: "center" }}>
                    입출고
                    <br />
                    수수료
                  </span>
                );
              }}
              field="outShopFee"
              cell={numberWithCommasCell}
            />
          </Column>
          <Column
            title="정산여부"
            width={100}
            field="salesReportType"
            cell={({ dataItem }) => {
              return (
                <td style={{ textAlign: "center" }}>
                  {dataItem["salesReportType"] === "SELF"
                    ? "자진발행"
                    : dataItem["salesReportType"] === "CASH"
                    ? "현금영수증"
                    : dataItem?.["salesReportType"] === "null"
                    ? "-"
                    : dataItem?.["salesReportType"] || "-"}
                </td>
              );
            }}
          />
          <Column
            title="거래번호"
            width={320}
            field="tradeNumber"
            cell={(props) => {
              let dataItem = props.dataItem;
              return (
                <CenterCell
                  {...props}
                  style={{
                    padding: "0 7px",
                    width: "320px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}>
                  {dataItem.tradeNumber}
                </CenterCell>
              );
            }}
          />
        </GridTable>
      </ListWrap>
    </HistoryWrap>
  );
};

export default TransactionIntegratedHistory;

const HistoryWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  user-select: none;
  .k-grid-table {
    overflow-y: scroll;
  }
`;

const Title = styled.div`
  width: 50%;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

const FilterWrap = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${(props) => (props.expanded ? "293px" : "0px")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  margin-top: 10px;
`;

const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
  th {
    width: 100px;
    background: #f2f4f6;
    text-align: left;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #636a75;
    user-select: none;
  }
  th,
  td {
    border: 1px solid #e2e5e8;
    border-left: none;
    border-right: none;
  }
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: none;
  background: #000;
  color: #fff;
  user-select: none;
  &:hover {
    opacity: 0.7;
  }
`;

const InitBtn = styled.button`
  width: 120px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const RefreshBtn = styled.button`
  width: 50px;
  height: 35px;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  background: #fff;
  user-select: none;
  &:hover {
    background: #ededed;
  }
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

const ListWrap = styled.div`
  width: 100%;
  height: ${(props) =>
    props.expanded ? "calc(100% - 315px)" : "calc(100% - 100px)"};
  max-height: ${(props) => (props.expanded ? "59vh" : "82vh")};
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
  background: gray;
  .k-grid-header-wrap {
    th {
      background: #f2f4f6;
      color: #636a75;
      font-weight: bold;
      text-align: center;
    }
  }
  .k-column-resizer {
    background: #f2f4f6;
  }
`;

const TradeTypeCheckBoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
`;

const AssetCheckBoxWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ExcelBtn = styled.button`
  width: 100px;
  height: 35px;
  background: #64a758;
  border-radius: 0.3rem;
  margin: 5px;
  border: 1px solid #ededed;
  color: #fff;
  font-weight: 500;
  &:hover {
    opacity: 0.7;
  }
`;

const DayBtn = styled.button`
  width: 100px;
  height: 35px;
  background: #3e90cf;
  border: none;
  margin: 3px;
  color: #fff;
  border-radius: 0.3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    opacity: 0.7;
  }
`;

const GoodsTradeTypeWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px;
  margin-left: 8px;

  /* 체크박스와 스타일 통일 */
  input {
    width: 20px;
    height: 20px;
    border-radius: 0.5rem;
    border: 1px solid rgb(190, 190, 190);

    /* 체크했을 때 스타일 변경 */
    :checked {
      border: 1px solid #4583ee;
      background-color: #4583ee;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='4' fill='white'/%3e%3c/svg%3e");
    }

    /* 라디오 버튼 클릭했을 때 파란 쉐도우 나오도록 변경 */
    :checked:focus {
      box-shadow: 0 0 0 0.2rem rgba(69, 131, 238, 0.25);
    }
  }

  label {
    font-size: 14px;
  }
`;

const TradeRadioButton = styled(RadioButton)`
  margin-left: 8px;
`;

import { GridColumn } from "@progress/kendo-react-grid";
import Flex from "components/flex/Flex";
import GridTable, {
  CenterCell,
  NumberCell,
} from "components/gird-table/GridTable";
import DateInput from "components/input/DateInput";
import TextInput from "components/input/TextInput";
import withProgressBar from "hoc/withProgressBar";
import SearchLayout, { SearchItem } from "layouts/search/SearchLayout";
import { formatTime, numberWithCommas } from "libs/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { actionError } from "redux/action/ActionActions";
import styled from "styled-components";
import * as APIS from "../../../libs/apis";
import { TiDocumentText } from "react-icons/ti";
import KendoButton from "components/button/KendoButton";
import routes from "libs/routes";

const getOneMonthAgo = () => {
  const currentDate = new Date();
  const dateCopy = new Date(currentDate);
  dateCopy.setMonth(currentDate.getMonth() - 1);
  return dateCopy;
};

const INIT_SEARCH_STATE = {
  fromDate: formatTime(getOneMonthAgo(), "YYYY-MM-DD"),
  toDate: formatTime(),
  tradeNo: "",
  userKeyword: "",
  shopName: "",
};
const gridName = "ConsignmentPurchasesStatus";

const ConsignmentPurchaseStatus = withProgressBar(({ progressBar }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { page, size = 100 } = locationState;
  const [total, setTotal] = useState(0);
  const [searchState, setSearchState] = useState(INIT_SEARCH_STATE);
  const [consignmentData, setConsignmentData] = useState([]);
  const [confirmWaitSearchState, setConfirmWaitSearchState] = useState(false);

  useEffect(() => {
    if (locationState.page || locationState.page === 0) {
      getConsignment({ ...locationState, size });
    }
  }, [locationState]);

  const handleChangeSearchState = (newState) => {
    setSearchState({ ...searchState, ...newState });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleClickSearch = () => {
    handleChangeLocationState({ ...searchState, page: 0 });
  };
  const handleClickConfirmWait = () => {
    setConfirmWaitSearchState(true);
    handleChangeLocationState({ ...searchState, page: 0 });
  };

  const getConsignment = (param) => {
    setConsignmentData([]);
    setTotal(0);
    progressBar.on();
    const api = confirmWaitSearchState
      ? APIS.getConsignmentWait({ page: 0, size })
      : APIS.getConsignmentSearch(param);

    api
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setConsignmentData(
            data.content.map((item, index) => {
              return {
                ...item,
                no: data.totalElements - (page * size + index),
                createdAt: item.createdAt
                  ? formatTime(item.createdAt, "YYYY-MM-DD HH:mm")
                  : "-",
                tradeAt: item.tradeAt
                  ? formatTime(item.tradeAt, "YYYY-MM-DD HH:mm")
                  : "-",
                assetType: item.assetType
                  .replace("GOLD", "금")
                  .replace("SILVER", "은"),
                appraisalWeightGram: `${item.appraisalWeightGram}g`,
                price: item.price ? numberWithCommas(item.price) : "-",
                paymentKrw: item.paymentKrw
                  ? numberWithCommas(item.paymentKrw)
                  : "-",
                shopFee: item.shopFee ? numberWithCommas(item.shopFee) : "-",
              };
            })
          );
          setTotal(data.totalElements);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => {
        progressBar.off();
        setConfirmWaitSearchState(false);
      });
  };

  const handleClickDetail = ({ dataItem: { id } }) => {
    history.push(routes.consignmentPurchaseStatusDetail, {
      id: id,
    });
  };

  const grid = useMemo(() => {
    return (
      <GridTable
        className="grid"
        name={gridName}
        reorderable
        style={{ height: 600 }}
        data={consignmentData}
        scrollable
        pageable
        skip={page * size}
        take={size}
        total={total}
        onPageChange={({ page: { skip, take: size } }) => {
          const page = skip / size;
          if (locationState.page !== page || locationState.size !== size) {
            handleChangeLocationState({ page, size });
          }
        }}
        onRowClick={handleClickDetail}>
        <GridColumn title="No" field="no" cell={CenterCell} width={50} />
        <GridColumn title="접수번호" field="tradeNo" cell={CenterCell} />
        <GridColumn title="접수일시" field="createdAt" cell={CenterCell} />
        <GridColumn title="완료일시" field="tradeAt" cell={CenterCell} />
        <GridColumn title="이름" field="userName" cell={CenterCell} />
        <GridColumn title="연락처" field="userPhone" cell={CenterCell} />
        <GridColumn title="자산" field="assetType" cell={CenterCell} />
        <GridColumn title="중량" field="appraisalWeightGram" />
        <GridColumn title="시세" field="price" cell={NumberCell} />
        <GridColumn title="지급액" field="paymentKrw" cell={NumberCell} />
        <GridColumn title="수수료" field="shopFee" cell={NumberCell} />
        <GridColumn title="대리점" field="shopName" cell={CenterCell} />
        <GridColumn title="상태" field="status" cell={CenterCell} />
        <GridColumn
          title="내역"
          cell={(props) => {
            const { reportPath = "" } = props.dataItem;
            const handleOpenReport = () => {
              if (reportPath) {
                window.open(reportPath, "_blank");
              }
            };
            return (
              <CenterCell {...props}>
                {reportPath ? (
                  <TiDocumentText
                    style={{ width: 25, height: 25, cursor: "pointer" }}
                    onClick={handleOpenReport}
                  />
                ) : (
                  <></>
                )}
              </CenterCell>
            );
          }}
        />
      </GridTable>
    );
  }, [total]);

  const { fromDate, toDate, tradeNo, userKeyword, shopName } = searchState;
  return (
    <Container>
      <SearchLayout
        title="위탁매입 매입현황"
        onSearch={handleClickSearch}
        onReset={() => handleChangeSearchState(INIT_SEARCH_STATE)}>
        <Flex row>
          <SearchItem header="기간">
            <DateInput
              value={fromDate}
              onChange={(fromDate) => handleChangeSearchState({ fromDate })}
              max={formatTime()}
            />{" "}
            ~{" "}
            <DateInput
              value={toDate}
              onChange={(toDate) => handleChangeSearchState({ toDate })}
              min={fromDate}
            />
          </SearchItem>
          <SearchItem header="접수번호">
            <TextInput
              value={tradeNo}
              onChange={(tradeNo) => handleChangeSearchState({ tradeNo })}
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="회원">
            <TextInput
              value={userKeyword}
              onChange={(userKeyword) =>
                handleChangeSearchState({ userKeyword })
              }
            />
          </SearchItem>
          <SearchItem header="대리점">
            <TextInput
              value={shopName}
              onChange={(shopName) => handleChangeSearchState({ shopName })}
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="검색 버튼">
            <KendoButton themeColor="info" onClick={handleClickConfirmWait}>
              승인대기
            </KendoButton>
          </SearchItem>
        </Flex>
      </SearchLayout>
      {grid}
    </Container>
  );
});
export default ConsignmentPurchaseStatus;

const Container = styled(Flex)`
  flex: 1;
  padding: 10px 25px;

  .grid {
    margin-top: 5px;
  }
`;

import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Flex from "../../components/flex/Flex";
import DataTable from "../../components/table/Table";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { numFormat } from "../../services/utils";
import { objToQueryStr, base64toBlob } from "../../services/utils";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { getDefaultPageSize } from "../../libs/consts";
import { saveAs } from "file-saver";
import { actionOpen, actionError } from "../../redux/action/ActionActions";
import styled from "styled-components";
import SearchLayout, { SearchItem } from "layouts/search/SearchLayout";
import DropDownList from "components/select/DropDownList";
import DateInput from "components/input/DateInput";
import TextInput from "components/input/TextInput";
import ButtonTabs from "components/tabs/ButtonTabs";
import KendoButton from "components/button/KendoButton";
import StyledTable from "components/table/StyledTable";
import withProgressBar from "hoc/withProgressBar";
import { RadioGroup } from "@progress/kendo-react-inputs";
import moment from "moment";

const INIT_SEARCH_STATE = {
  startDate: formatTime(),
  endDate: formatTime(),
  tab: "1", // 체결, 미체결
  tradeType: "", // 전체, 매수, 매도
  assetType: "GOLD",
  searchName: "", //고객명, 휴대폰번호, 고객번호
};

const assetRadioData = [
  {
    label: "금",
    value: "GOLD",
  },
  {
    label: "은",
    value: "SILVER",
  },
];

const TRADE_TYPE_OPTIONS = [
  { value: "BUY", label: "매수" },
  { value: "SELL", label: "매도" },
];

const Trading = withProgressBar(({ progressBar }) => {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state || {};
  const [searchState, setSearchState] = useState(INIT_SEARCH_STATE);
  const [total, setTotal] = useState(0);
  const [tradePriceformat, settradePriceformat] = useState("");
  const [tradeList, settradeList] = useState([]);
  const [summary, setSummary] = useState({}); // 기존 tradeListtotal (총주문, 기간내체결중량, 주문대금)
  const isFirstRender = useRef(true);

  const handleChangeSearchState = (newState) => {
    setSearchState({ ...searchState, ...newState });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...state,
      ...newState,
    });
  };

  const handleClickSearch = () => {
    handleChangeLocationState({ ...searchState, page: 0 });
  };

  useEffect(() => {
    let assetType = state?.assetType || "GOLD";
    progressBar.on();
    APIS.getcurrentmarketprice(assetType)
      .then(({ data: { data } }) => {
        settradePriceformat(numFormat(data));
      })
      .finally(progressBar.off);
  }, [state.assetType]);

  useEffect(() => {
    if (isFirstRender.current === "initialized") {
      isFirstRender.current = false; // 초기화 이후 플래그 리셋
      return;
    }

    if (isFirstRender.current) {
      isFirstRender.current = "initialized";

      if (Object.keys(state).length === 0) {
        history.replace(location.pathname, {
          ...state,
          tab: "1",
          assetType: "GOLD",
          ...getDefaultPageSize(),
        });
      }
      return;
    }

    if (state.tab && state.page !== undefined) {
      fetchList({ ...state });
    }
  }, [state]);

  const handleChangeTabs = (tab) => {
    handleChangeSearchState({ tab });
    handleChangeLocationState({ ...searchState, tab: tab, page: 0 });
  };

  const fetchList = () => {
    settradeList([]);
    setTotal(0);
    setSummary({});
    progressBar.on();

    const param = {
      page: state.page,
      size: state.size,
      beginDate: startDate,
      endDate: endDate,
      tradeType,
      userCondition: state.searchName,
      assetType: state.assetType || "GOLD",
    };

    var status = "notsigned";
    if (state.tab === "1") {
      status = "done";
    }
    const paramQuery = objToQueryStr(param);
    const fetchApis = [
      APIS.getTradeList(status, paramQuery).then(
        ({
          data: {
            data: { content, totalElements },
          },
        }) => {
          settradeList(content);
          setTotal(totalElements);
        }
      ),
      APIS.getTradeSummaryList(status, paramQuery).then(
        ({
          data: {
            data: { totalAmount, totalKrw },
          },
        }) => {
          setSummary({
            ...summary,
            grams: totalAmount > 0 ? numFormat(totalAmount) : 0,
            amount: totalKrw > 0 ? numFormat(totalKrw) : 0,
          });
        }
      ),
    ];
    Promise.all(fetchApis).finally(progressBar.off);
  };

  const TradeDataTable = useMemo(() => {
    return (
      <DataTable
        rootStyle={classes.rootStyle}
        name={`${location.pathname === routes.tradingSilver ? "은" : "금"} ${
          state.tab === "1" ? "체결" : "미체결"
        } 현황`}
        hideLabel
        data={tradeList}
        totalCount={total}
        columns={
          state.tab === "1"
            ? [
                { label: "거래 시간", key: "completedAt", type: "datetime" },
                { label: "거래 매체", key: "tradePlace" },
                { label: "구분", key: "tradeType", type: "tradetype" },
                { label: "체결가격", key: "pricePerGram", type: "number" },
                { label: "거래금액", key: "pureTradeKrw", type: "number" },
                { label: "수수료", key: "userFee", type: "number" },
                { label: "체결량", key: "tradeGram", type: "number" },
                { label: "고객번호", key: "userId" },
                { label: "회원명", key: "userName" },
                { label: "휴대폰", key: "userPhoneNumber", type: "phone" },
                { label: "추천매장", key: "recommendShopName" },
                { label: "감정매장", key: "inOutShopName" },
                { label: "이용대리점", key: "usingShopName" },
              ]
            : [
                { label: "등록 시간", key: "created_at", type: "datetime" },
                { label: "구분", key: "tradeType", type: "tradetype" },
                { label: "주문가격", key: "orderPrice", type: "number" },
                { label: "주문수량", key: "requestGram", type: "number" },
                { label: "미체결량", key: "remainGram", type: "number" },
                { label: "주문자", key: "userName" },
                { label: "고객번호", key: "userId" },
                { label: "휴대폰", key: "userPhoneNumber", type: "phone" },
                { label: "추천매장", key: "recommendShopName" },
                { label: "감정매장", key: "inOutShopName" },
                { label: "이용대리점", key: "usingShopName" },
              ]
        }
      />
    );
  }, [tradeList, total, state?.tab]);

  const onClickExcelDownload = () => {
    progressBar.on();
    const param = {
      beginDate: state.startDate,
      endDate: state.endDate,
      tradeType: state.tradeType,
      userCondition: state.searchName,
      assetType: state.assetType || "GOLD",
    };

    for (let key in param) {
      const value = param[key];

      if (typeof value === "string" && !value) {
        delete param[key];
      }
    }
    let status = "notsigned";
    if (state.tab === "1") {
      status = "done";
    }
    APIS.getTradeListExcel(status, objToQueryStr(param))
      .then((response) => {
        const blob = new Blob([response.data]);
        saveAs(
          blob,
          `매수/매도_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(progressBar.off);
  };

  const calculateDateRange = (type) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    switch (type) {
      case "day":
        return { startDate: today, endDate: today };
      case "week":
        return {
          startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
          endDate: today,
        };
      case "1m":
        return {
          startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
          endDate: today,
        };
      case "3m":
        return {
          startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
          endDate: today,
        };
      default:
        return { startDate: today, endDate: today };
    }
  };

  const onClickDay = (e) => {
    const { name } = e.target;
    const dateRange = calculateDateRange(name);

    handleChangeSearchState(dateRange);
  };

  const { tab, assetType, startDate, endDate, tradeType, searchName } =
    searchState;

  return (
    <Container>
      <SearchLayout
        title="매수/매도"
        onSearch={handleClickSearch}
        onReset={() => handleChangeSearchState(INIT_SEARCH_STATE)}>
        <Flex row>
          <SearchItem header="조회기간">
            <DateInput
              value={startDate}
              onChange={(startDate) => handleChangeSearchState({ startDate })}
              max={formatTime()}
            />{" "}
            ~{" "}
            <DateInput
              value={endDate}
              onChange={(endDate) => handleChangeSearchState({ endDate })}
              min={startDate}
            />
            <div
              style={{
                width: "400px",
                height: "100%",
                marginLeft: "10px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}>
              <DayBtn name="day" onClick={onClickDay}>
                당일
              </DayBtn>
              <DayBtn name="week" onClick={onClickDay}>
                1주일
              </DayBtn>
              <DayBtn name="1m" onClick={onClickDay}>
                1개월
              </DayBtn>
              <DayBtn name="3m" onClick={onClickDay}>
                3개월
              </DayBtn>
            </div>
          </SearchItem>
          <SearchItem header="구분">
            <DropDownList
              useAll
              options={TRADE_TYPE_OPTIONS}
              selected={tradeType ? tradeType : ""}
              onChange={(tradeType) => handleChangeSearchState({ tradeType })}
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="자산">
            <RadioGroup
              data={assetRadioData}
              layout={"horizontal"}
              value={assetType}
              onChange={(e) => handleChangeSearchState({ assetType: e.value })}
            />
            <Text font={fonts.notoSansKRBold} className={classes.label2}>
              {tradePriceformat}KRW
            </Text>
          </SearchItem>
          <SearchItem header="고객정보">
            <TextInput
              placeholder="고객명, 휴대폰번호, 고객번호"
              value={searchName}
              onChange={(searchName) => handleChangeSearchState({ searchName })}
              autoFocus={true}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchList();
                }
              }}
            />
          </SearchItem>
        </Flex>
      </SearchLayout>
      <Flex style={{ position: "relative", marginTop: 10 }}>
        <ButtonTabs
          options={[
            { label: "체결 현황", value: "1" },
            { label: "미체결 현황", value: "2" },
          ]}
          selected={state.tab}
          onSelect={handleChangeTabs}
        />
      </Flex>
      <Flex row className="content-header">
        <Flex>
          {!!total && (
            <StyledTable>
              <Flex row>
                <Flex className="header">총 주문</Flex>
                <Flex className="data">{numberWithCommas(total)} 건</Flex>
                <Flex className="header">{`기간 내 ${
                  tab === "1" ? "체결" : "미체결"
                }중량`}</Flex>
                <Flex className="data">
                  {numberWithCommas(summary.grams)} g
                </Flex>
                <Flex className="header">주문대금</Flex>
                <Flex className="data">
                  {`${numberWithCommas(summary.amount)} KRW`}
                </Flex>
              </Flex>
            </StyledTable>
          )}
        </Flex>
        <Flex row>
          <KendoButton
            icon="excel"
            label="Excel"
            onClick={onClickExcelDownload}
          />
        </Flex>
      </Flex>
      {TradeDataTable}
    </Container>
  );
});
export default Trading;

const Container = styled(Flex)`
  flex: 1;
  padding: 10px 30px;

    .content-header {
    margin-top: 10px;
    justify-content: space-between;

    .header {
      width: 135px;
    }
    .data {
      min-width: 140px;
      white-space: nowrap;
      justify-content: center;
    }
  }
`;

const DayBtn = styled.button`
  width: 100px;
  height: 35px;
  background: rgb(242, 244, 246);
  border: none;
  margin: 3px;
  color: black;
  border-radius: 0.3rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  &:hover {
    opacity: 0.7;
  }
`;
const useStyle = makeStyles({
  rootStyle: {},

  label2: {
    color: "red",
    marginLeft: 16,
  },
});

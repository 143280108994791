import Flex from "components/flex/Flex";
import SmallTitle from "components/title/SmallTitle";
import React, { useEffect, useState } from "react";
import GridTable, {
  NumberCell,
  CenterCell,
} from "components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import * as APIS from "libs/apis";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { useDispatch } from "react-redux";
import { objToQueryStr } from "services/utils";
import { formatTime, numberWithCommas } from "libs/utils";
import KendoButton from "../../../components/button/KendoButton";
import DateInput from "../../../components/input/DateInput";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import { makeStyles } from "@material-ui/core";

const PriceTable = ({ assetType, title }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 10);
  const initialFilter = {
    fromDate: formatTime(startDate),
    toDate: formatTime(today),
    page: 0,
    size: 10,
    total: 0,
  };
  const [filter, setFilter] = useState({ ...initialFilter });
  const [kgePrice, setKgePrice] = useState(0);
  const [list, setList] = useState([]);
  const [insertPrice, setInsertPrice] = useState(null);

  useEffect(() => {
    dispatch(loadingStart);
    const kgePriceApi = APIS.getConsignmentKgePrice(
      objToQueryStr({ assetType })
    )
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setKgePrice(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));

    const fetchListApi = fetchList(filter);
    Promise.all([kgePriceApi, fetchListApi]).finally(() => {
      dispatch(loadingEnd);
    });
  }, [assetType]);

  const fetchList = (param) => {
    dispatch(loadingStart);
    APIS.getConsignmentPrice(
      objToQueryStr({
        ...param,
        assetType,
      })
    )
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setList(data.content);
          setFilter({
            ...filter,
            page: data.number,
            size: data.size,
            total: data.totalElements,
          });
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => dispatch(loadingEnd));
  };

  const handleChangeSearchValue = (value = {}) => {
    setFilter({ ...filter, ...value });
  };

  const handleChangePage = ({ page }) => {
    fetchList({
      ...filter,
      page: page.skip / page.take,
      size: page.take,
    });
  };

  const handleClickInsert = (adminPassword) => {
    const data = {
      assetType,
      kgePrice: kgePrice,
      price: insertPrice,
      password: adminPassword,
    };
    dispatch(loadingStart);
    APIS.postConsignmentPrice(data)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            actionOpen("시세가 변경되었습니다.", null, null, null, true)
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => {
        fetchList(filter);
        dispatch(loadingEnd);
      });
  };

  return (
    <Flex style={{ width: "50%" }}>
      <Flex row style={{ justifyContent: "space-between" }}>
        <SmallTitle>{title}</SmallTitle>
        <SmallTitle>
          *KGE : {kgePrice !== null ? numberWithCommas(kgePrice) : "-"}
        </SmallTitle>
      </Flex>
      <Flex row style={{ justifyContent: "space-between" }}>
        <Flex row>
          <input
            placeholder="시세입력"
            value={insertPrice !== null ? numberWithCommas(insertPrice) : ""}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/,/g, "");
              if (!isNaN(inputValue) && inputValue !== "") {
                setInsertPrice(Number(inputValue));
              } else if (inputValue === "") {
                setInsertPrice(null);
              }
            }}
          />
          <AdminConfirmButton
            rootClassName={classes.confirmRoot}
            btnClassName={classes.confirmButton}
            popupLabel="시세 변경"
            popupMessage={`${title} 위탁 시세를 ${numberWithCommas(
              insertPrice
            )}원으로 변경하시겠습니까?`}
            label="변경"
            callback={handleClickInsert}
          />
        </Flex>
        <Flex row>
          <DateInput
            value={filter.fromDate}
            onChange={(value) => handleChangeSearchValue({ fromDate: value })}
            max={formatTime()}
          />
          ~
          <DateInput
            value={filter.toDate}
            onChange={(value) => handleChangeSearchValue({ toDate: value })}
            min={filter.fromDate}
          />
          <KendoButton onClick={() => fetchList(filter)}>조회</KendoButton>
        </Flex>
      </Flex>
      <GridTable
        className="grid"
        name={`ConsignmentPrice${assetType}`}
        reorderable
        style={{ height: 600 }}
        data={list}
        scrollable
        pageable
        skip={filter.page * filter.size}
        take={filter.size}
        total={filter.total}
        onPageChange={handleChangePage}>
        <GridColumn
          title="일시"
          field="priceAt"
          cell={(props) => {
            const { priceAt } = props.dataItem;
            return (
              <td style={{ textAlign: "center" }}>
                {`${formatTime(priceAt, "YYYY-MM-DD HH:mm")}`}
              </td>
            );
          }}
        />
        <GridColumn title="위탁시세" field="price" cell={NumberCell} />
        <GridColumn
          title="등락"
          field="fluctuations"
          cell={(props) => {
            const { fluctuationRate, fluctuations } = props.dataItem;
            return (
              <td style={{ textAlign: "center" }}>
                {`${numberWithCommas(fluctuations)}(${fluctuationRate || 0}%)`}
              </td>
            );
          }}
        />
        <GridColumn title="KGE 시세" field="kgePrice" cell={NumberCell} />
        <GridColumn title="금방시세" field="gbPrice" cell={NumberCell} />
        <GridColumn title="등록자" field="managerName" cell={CenterCell} />
      </GridTable>
    </Flex>
  );
};

export default PriceTable;

const useStyle = makeStyles({
  confirmRoot: {
    margin: 0,
    marginLeft: 0,
    alignItems: "unset",
    marginRight: 40,
  },
  confirmButton: {
    minWidth: 70,
    height: 38,
    width: 40,
    marginLeft: 0,
  },
});
